import React, { FC, useContext } from 'react';
import { Row, Col, Dropdown, Button, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { Colors } from '../../../../core/shared/constants/colors';
import { useSelector } from 'react-redux';
import {
    setShowAlertFeedback,
    getShowAcquirePlatformComponent,
} from '../../../../store/internal';
import constsApi from '../../../../services/constsApi';
import ChatEditor from '../chatEditor';
import { MessagesComponentInterface } from './indexModel';
import ModalSendTicketCopy from '../../components/modalSendTicketCopy';
import ModalListTasksController from '../modalTasks/indexController';
import TemplateMessageController from './components/modalTemplateMessage/indexController';
import ModalHistoryLogController from './components/modalHistoryLog/indexController';
import ModalCC_CCO_UpdateController from './components/cc_cco/modalUpdateTicket/indexController';
import ModalCC_CCO_CreateController from './components/cc_cco/modalCreateTicket/indexController';
import ModalGeneralTypesController from './components/modalGeneralType/indexController';
import { BlockTicketContext } from '../../../../core/context/block-ticket-context';
import { Channels } from '../../../../core/enums/channels';

const MessagesComponent: FC<MessagesComponentInterface> = (props) => {

    const block_ticket = useContext(BlockTicketContext);

    const popoverOptions = (data) => (
        <Popover id={"popOverOptionsId"} style={{
            zIndex: '500',
            backgroundColor: '#e6e6e6',
            border: 'none',
            color: '#40484E',
            font: 'var(--px15_19-font-Roboto)'
        }}>
            <Popover.Body className="nopadding">
                {(props.showOptionsButton && block_ticket.admBlocked === false) &&
                    <>
                        <Button onClick={props.deleteTicket} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{props.t('home_tickets.messages_component.delete_ticket')}</Button>
                        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                    </>
                }
                <Button onClick={(e) => props.selectModalTicket(e, data)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{props.t('home_tickets.messages_component.send_ticket_copy')}</Button>
            </Popover.Body>
        </Popover>
    );

    const popOverTemplateMessage = (
        <Popover id={"popOverTemplateMessage"}
            style={{
                border: 'none', marginRight: '7vw', marginTop: "4.5vh"
            }}
            data-trigger="focus"
        >
            <Popover.Body className="nopadding">
                <TemplateMessageController currentChannelId={props.currentChannelId} callbackTemplateMessage={(message) => props.callbackTemplateMessage(message)}
                    templateMessageList={props.templateMessageList}
                    showTemplateMessage={() => props.showTemplateMessage()}
                    currentTicket={props.currentTicket}
                    callbackQuickMessage={(message) => props.callbackQuickMessage(message)}
                    hasLastMessageFromConsumer={false}                
                ></TemplateMessageController>
            </Popover.Body>
        </Popover>
    );

    const popHistoryLog = (
        <Popover id={"popOverHistoryLog"}
            style={{
                border: 'none', marginTop: "0vh", paddingTop: '0', marginRight: '4vw'
            }}
            data-trigger="focus"
        >
            <Popover.Body className="nopadding">
                <ModalHistoryLogController
                    showHistoryLog={() => props.showHistoryLog()}
                    setShowPopoverHistoryLog={props.setShowPopoverHistoryLog}
                    requester={props.currentRequester_user}
                    currentTicket={props.currentTicket}
                    getTicket={props.getTicket}
                >
                </ModalHistoryLogController>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <ModalListTasksController show={props.showCustomFieldModalEdit} handleClose={props.handleCloseCustomFieldModalEdit} data={props.currentTicket?.id} callBackModalCustomField={props.callBackModalCustomField}
                updateListfunction={props.updateListfunction} />
            {props.values.showSendTicketCopyModal && <ModalSendTicketCopy token={props.values.token.value} visibility={props.values.showSendTicketCopyModal} data={props.ticketModal}></ModalSendTicketCopy>}
            <Row style={{ backgroundColor: '#EEF3FF', height: '4vh', color: '#0C2461', font: 'var(--px15_19_Bold-font)' }}
                className="d-flex align-items-center justify-content-center mx-1">
                {
                    props.currentTicket != null &&
                    <Col md>
                        <Row>
                            <Col className="d-flex align-items-center ps-4" style={{}}>
                                {props.getTicketHeaderInfo()}
                                {props.currentTicket.status_id === constsApi.status[1].id ?
                                    <Button className="d-flex align-items-center justify-content-center mx-4" style={{
                                        backgroundColor: '#707070',
                                        border: 'none',
                                        width: '20px',
                                        height: '20px',
                                        lineHeight: '1',
                                        textIndent: '-2',
                                        padding: '0rem 0rem'
                                    }}>
                                        <OverlayTrigger
                                            key='top'
                                            placement='top'
                                            overlay={
                                                <Tooltip id='tooltip'>
                                                    {props.t('home_tickets.messages_component.resolved')}
                                                </Tooltip>
                                            }
                                        >
                                            <span className='FilterTicketsRow '>R</span>
                                        </OverlayTrigger>
                                    </Button>
                                    :
                                    props.currentTicket.status_id === constsApi.status[2].id ?
                                        <Button className="d-flex align-items-center justify-content-center mx-4"
                                            style={{
                                                backgroundColor: '#DE2450',
                                                border: 'none',
                                                width: '20px',
                                                height: '20px',
                                                lineHeight: '1',
                                                textIndent: '-2',
                                                padding: '0rem 0rem'
                                            }}>
                                            <OverlayTrigger
                                                key='top'
                                                placement='top'
                                                overlay={
                                                    <Tooltip id='tooltip'>
                                                        {props.t('home_tickets.messages_component.pending')}
                                                    </Tooltip>
                                                }
                                            >
                                                <span className='FilterTicketsRow '>P</span>
                                            </OverlayTrigger>
                                        </Button>
                                        :
                                        props.currentTicket.status_id === constsApi.status[3].id ?
                                            <Button className="d-flex align-items-center justify-content-center mx-4"
                                                style={{
                                                    backgroundColor: '#FBBD4E',
                                                    border: 'none',
                                                    width: '20px',
                                                    height: '20px',
                                                    lineHeight: '1',
                                                    textIndent: '-2',
                                                    padding: '0rem 0rem'
                                                }}>
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {props.t('home_tickets.messages_component.on_hold')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className='FilterTicketsRow '>E</span>
                                                </OverlayTrigger>
                                            </Button>
                                            :
                                            props.currentTicket.status_id === constsApi.status[0].id ?
                                                <Button
                                                    className="d-flex align-items-center justify-content-center mx-4"
                                                    style={{
                                                        backgroundColor: '#4A69BD',
                                                        border: 'none',
                                                        width: '20px',
                                                        height: '20px',
                                                        lineHeight: '1',
                                                        textIndent: '-2',
                                                        padding: '0rem 0rem'
                                                    }}>
                                                    <OverlayTrigger
                                                        key='top'
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip id='tooltip'>
                                                                {props.t('home_tickets.messages_component.open')}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='FilterTicketsRow '>A</span>
                                                    </OverlayTrigger>
                                                </Button>
                                                :
                                                props.currentTicket.status_id === constsApi.status[4].id ?
                                                    <Button
                                                        className="d-flex align-items-center justify-content-center mx-4"
                                                        style={{
                                                            backgroundColor: '#17a589',
                                                            border: 'none',
                                                            width: '20px',
                                                            height: '20px',
                                                            lineHeight: '1',
                                                            textIndent: '-2',
                                                            padding: '0rem 0rem',
                                                        }}>
                                                        <OverlayTrigger
                                                            key='top'
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id='tooltip'>
                                                                    {props.t('home_tickets.messages_component.new')}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className='FilterTicketsRow '>N</span>
                                                        </OverlayTrigger>
                                                    </Button>
                                                    :
                                                    props.currentTicket.status_id === constsApi.status[5].id ?
                                                        <Button
                                                            className="d-flex align-items-center justify-content-center mx-4"
                                                            style={{ backgroundColor: '#2D3233', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {props.t('home_tickets.messages_component.abandoned')}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className='FilterTicketsRow '>B</span>
                                                            </OverlayTrigger>
                                                        </Button> :
                                                        null
                                }
                            </Col>
                        </Row>
                    </Col>
                }

                {
                    props.currentTicket !== null && props.showHistoryButton &&
                    <Col md={1}>
                        <Row className='justify-content-end'>
                            <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                    <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                        {props.t('home_tickets.messages_component.task')}
                                    </Tooltip>
                                }
                            >
                                <Button className='nopadding' style={{
                                    background: '#CFDCFF 0% 0% no-repeat padding-box',
                                    borderRadius: '4px',
                                    opacity: '1',
                                    borderColor: '#CFDCFF',
                                    height: '4vh',
                                    width: '4vh'
                                }} onClick={() => {
                                    props.setShowPopoverHistoryLog(false)
                                    props.handleShowCustomFieldModalEdit()
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-checkup-list" width="24px" height="24px" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4a69bd" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                                        <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                        <path d="M9 14h.01" />
                                        <path d="M9 17h.01" />
                                        <path d="M12 16l1 1l3 -3" />
                                    </svg>
                                </Button>
                            </OverlayTrigger>
                        </Row>
                    </Col>
                }

                {
                    props.currentTicket !== null && props.showHistoryButton &&
                    <Col md={1}>
                        <Row className='justify-content-end'>
                            <OverlayTrigger rootClose={props.showPopoverHistoryLog} show={props.showPopoverHistoryLog} onToggle={() => props.showHistoryLog()} trigger={['click']} placement="bottom-end" overlay={popHistoryLog}>
                                <Button className='nopadding' style={{
                                    background: '#CFDCFF 0% 0% no-repeat padding-box',
                                    borderRadius: '4px',
                                    opacity: '1',
                                    borderColor: '#CFDCFF',
                                    height: '4vh',
                                    width: '4vh'
                                }} onClick={() => props.showHistoryLog()}>
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                {props.t('home_tickets.messages_component.history')}
                                            </Tooltip>
                                        }
                                    >
                                        <svg id="Grupo_11202" data-name="Grupo 11202" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                            <path id="Caminho_9588" data-name="Caminho 9588" d="M0,0H24V24H0Z" fill="none" />
                                            <path id="Caminho_9589" data-name="Caminho 9589" d="M12,8v4l2,2" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_9590" data-name="Caminho 9590" d="M3.05,11a9,9,0,1,1,.5,4m-.5,5V15h5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </OverlayTrigger>

                                </Button>
                            </OverlayTrigger>
                        </Row>
                    </Col>
                }
                {
                    props.currentTicket != null &&
                    <Col md={1} >
                        <Row className="justify-content-end">
                            <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popoverOptions(props.currentTicket)}>
                                <Button className='nopadding' style={{
                                    background: '#CFDCFF 0% 0% no-repeat padding-box',
                                    borderRadius: '4px',
                                    opacity: '1',
                                    borderColor: '#CFDCFF',
                                    height: '4vh',
                                    width: '4vh',
                                }} onClick={() => props.setShowPopoverHistoryLog(false)}>
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                {props.t('home_tickets.messages_component.actions')}
                                            </Tooltip>
                                        }
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                                            strokeWidth="1.5" stroke="#4a69bd" fill="#4a69bd" strokeLinecap="round"
                                            strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <circle cx="12" cy="12" r="1" />
                                            <circle cx="12" cy="19" r="1" />
                                            <circle cx="12" cy="5" r="1" />
                                        </svg>
                                    </OverlayTrigger>

                                </Button>
                            </OverlayTrigger>
                        </Row>
                    </Col>
                }
            </Row>

            <Row>
                <Col style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: (
                        // usando 88% de quando não é da tela de histórico
                        props.isHistoryHome ?
                            (props.getShowAcquirePlatformComponent.visibility ? '61vh' : '63vh') :
                            (props.getShowAcquirePlatformComponent.visibility ? '70vh' : '72vh')
                    ),
                    overflowY: 'hidden'
                }}>
                    <div style={{resize: 'vertical', overflow: 'auto', height: '50vh', minHeight: '150px'}}>
                        <Col id="#chat" className="mx-1" style={{
                            backgroundColor: '#f8f9fa',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: "100%",
                            borderRadius: '5px',
                            flexDirection: 'column-reverse'
                        }} onScroll={() => props.onScroll()}>
                            <div className="chat-wrapper" style={{
                                display: props.renderMessagesChat == false ? 'flex' : 'none',
                                flexFlow: 'column nowrap',
                                justifyContent: 'flex-end',
                                minHeight: '100%'
                            }}>
                                {props.renderMessages()}
                            </div>
                            <div ref={props.messagesEndRef} />
                        </Col>
                    </div>

                    {props.editTicket === false ?
                        <></>
                        :
                        <Row className="justify-content-start py-2 mt-1 mx-1" style={{
                            border: `1px solid ${Colors.PrimaryGrey}`,
                            backgroundColor: '#f8f9fa',
                            borderRadius: '4px',
                            font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                            color: '#707070',
                            display: props.hiddenChatEditor ? 'none' : 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            /* minHeight: props.changeChatHeight === false ? '200px' : '280px' */
                        }}>

                            <Row className='nopadding'>
                                <Col md={4}>
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        trigger={['hover', 'focus', 'click']}
                                        overlay={
                                            <Tooltip>
                                                {props.currentChannelId == Channels.WhatsApp ? <>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start", fontWeight: "bold" }}>{props.t('home_tickets.chat_editor.channel_info_title')}</span><br/>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_info_max')}</span><br/>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_audio_file')} 25 Mb</span>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_file')} 100 Mb</span>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_image_file')} 5 Mb</span>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_sticker_file')} 0.09 Mb</span>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_video_file')} 16 Mb</span><br/>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_type')}</span><br/>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_audio_file')}</span>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>.aac, .amr, .mp3, .m4a, .ogg</span><br/>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_file')}</span>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>.txt, .xls, .xlsx, .doc, .docx, .ppt</span>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>.pptx, .pdf</span><br/>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_image_file')}</span>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>.jpeg, .png</span><br/>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_sticker_file')}</span>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>.webp</span><br/>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_video_file')}</span>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start" }}>.3gp, .mp4</span>
                                                </> : props.currentChannelId == Channels.Email ? <>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start", fontWeight: "bold" }}>{props.t('home_tickets.chat_editor.channel_info_title')}</span><br/>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_info_max')} 25 Mb</span><br/>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_type')} {props.t('home_tickets.chat_editor.all')}</span><br/>
                                                </> : props.currentChannelId == Channels.Webchat ? <>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start", fontWeight: "bold" }}>{props.t('home_tickets.chat_editor.channel_info_title')}</span><br/>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_info_max')} 25 Mb</span><br/>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_type')} {props.t('home_tickets.chat_editor.all')}</span><br/>
                                                </> : props.currentChannelId == Channels.Instagram ? <>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start", fontWeight: "bold" }}>{props.t('home_tickets.chat_editor.channel_info_title')}</span><br/>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_info_max')}</span><br/>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_audio_file')} 25 Mb</span>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_image_file')} 8 Mb</span>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_video_file')} 25 Mb</span><br/>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_type')}</span><br/>
                                                        <span style={{ display: "flex", justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_audio_file')}</span>
                                                        <span style={{ display: "flex", justifyContent: "flex-start" }}>.aac, .m4a, .wav, .mp4</span><br/>
                                                        <span style={{ display: "flex", justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_image_file')}</span>
                                                        <span style={{ display: "flex", justifyContent: "flex-start" }}>.jpeg, .png, .gif</span><br/>
                                                        <span style={{ display: "flex", justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_video_file')}</span>
                                                        <span style={{ display: "flex", justifyContent: "flex-start" }}>.mp4</span>
                                                </> : props.currentChannelId == Channels.Facebook ? <>
                                                        <span style={{ display: "flex", justifyContent: "flex-start", fontWeight: "bold" }}>{props.t('home_tickets.chat_editor.channel_info_title')}</span><br/>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_info_max')}</span><br/>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_audio_file')} 25 Mb</span>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_image_file')} 8 Mb</span>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_video_file')} 25 Mb</span>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_type')}</span><br/>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_audio_file')}</span>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>.aac, .m4a, mp4</span><br/>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_image_file')}</span>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>.jpeg, .png, .gif</span><br/>
                                                        <span style={{ display: "flex", justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_video_file')}</span>
                                                        <span style={{ display: "flex", justifyContent: "flex-start" }}>.mp4</span>

                                                </> : props.currentChannelId == Channels.Telefonia ? <>
                                                        <span style={{ display: "flex", justifyContent: "flex-start", fontWeight: "bold" }}>{props.t('home_tickets.chat_editor.channel_info_title')}</span><br/>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_info_max')} 25 Mb</span><br/>
                                                        <span style={{ display: 'flex', justifyContent: "flex-start" }}>{props.t('home_tickets.chat_editor.channel_type')} {props.t('home_tickets.chat_editor.all')}</span><br/>
                                                </> : ""}
                                            </Tooltip>
                                        }
                                    >
                                        <Dropdown
                                            drop={'up'}
                                            id="dropdownChat"
                                            defaultValue={"Whatsapp"}
                                            onSelect={(value) => props.onSelectResponseType(value)}
                                            onClick={
                                                () => {
                                                    if (props.isCreatingTicket) {
                                                        props.dispatch(setShowAlertFeedback(
                                                            { visibility: true, message: props.t('home_tickets.messages_component.right_option'), signalIcon: false }
                                                        ));
                                                    }
                                                }
                                            }
                                        >
                                            <Dropdown.Toggle
                                                id="select_resposta"
                                                disabled={props.isCreatingTicket || props.disabledDropdown || props.admBlocked}
                                                className="dropdownButtonTicketsComunication"
                                                style={{
                                                    backgroundColor: '#E5ECFF',
                                                    height: 'min(max(35px, 5vh), 65px)',
                                                    borderRadius: '3px'
                                                }}
                                            >
                                                <span style={{
                                                    position: 'absolute',
                                                    height: '60%',
                                                    alignItems: 'center',
                                                    display: 'flex'
                                                }}>{props.t('home_tickets.messages_component.public_reply')}</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="my-1" style={{
                                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                boxShadow: '0px 3px 6px #00000029',
                                                opacity: '1',
                                                width: '15vw'
                                            }}>
                                                <Dropdown.Header
                                                    className="my-2"
                                                    style={{
                                                        font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat',
                                                        textAlign: 'left',
                                                        letterSpacing: '0px',
                                                        color: '#1E1E1E',
                                                        opacity: 1,
                                                    }}>
                                                    {props.t('home_tickets.messages_component.communication_channel_a')}<br></br>{props.t('home_tickets.messages_component.communication_channel_b')}
                                                </Dropdown.Header>
                                                {props.onlyRead ?
                                                    <></>
                                                    :
                                                    <Dropdown.Item eventKey="chamadaSemNumero" id="responseNumber"
                                                        onClick={() => props.setIsDefaultClick()} onMouseOver={() => {
                                                            props.itemDropOverlay("responseNumberInsert")
                                                        }} onMouseOut={() => {
                                                            props.itemDropOut("responseNumberInsert")
                                                        }}>
                                                        <Row className="d-flex align-items-center py-2">
                                                            <Col sm={2} md={2}>
                                                                <svg id="Grupo_11310" data-name="Grupo 11310"
                                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                    viewBox="0 0 18.692 18.692">
                                                                    <path id="Caminho_9669" data-name="Caminho 9669"
                                                                        d="M0,0H18.692V18.692H0Z" fill="none" />
                                                                    <path id="Caminho_9670" data-name="Caminho 9670"
                                                                        d="M4.558,4H7.673L9.231,7.894,7.284,9.063a8.567,8.567,0,0,0,3.894,3.894l1.168-1.947,3.894,1.558v3.115a1.558,1.558,0,0,1-1.558,1.558A12.462,12.462,0,0,1,3,5.558,1.558,1.558,0,0,1,4.558,4"
                                                                        transform="translate(-0.663 -0.885)" fill="none"
                                                                        stroke="#707070" strokeLinecap="round"
                                                                        strokeLinejoin="round" strokeWidth="1" />
                                                                    <line id="Linha_197" data-name="Linha 197" y1="3.894" x2="3.894"
                                                                        transform="translate(11.683 3.115)" fill="none"
                                                                        stroke="#707070" strokeLinecap="round"
                                                                        strokeLinejoin="round" strokeWidth="1" />
                                                                    <path id="Caminho_9671" data-name="Caminho 9671"
                                                                        d="M16,4h3.115V7.115" transform="translate(-3.538 -0.885)"
                                                                        fill="none" stroke="#707070" strokeLinecap="round"
                                                                        strokeLinejoin="round" strokeWidth="1" />
                                                                </svg>
                                                            </Col>

                                                            <Col sm={8} md={8}>
                                                                {props.t('home_tickets.messages_component.call_a')} <br /> {props.t('home_tickets.messages_component.call_b')}
                                                            </Col>

                                                            <Col sm={1} md={1} id="responseNumberInsertCheck"
                                                                style={{ display: 'none' }}>
                                                                {props.CheckIcon()}
                                                            </Col>
                                                        </Row>
                                                    </Dropdown.Item>
                                                }
                                                {props.onlyRead ?
                                                    <></>
                                                    :
                                                    <>
                                                        {
                                                            props.currentRequester_user && props.currentRequester_user.channels &&

                                                            props.currentRequester_user.channels.map((item: { id: string; }, index: React.Key) => {
                                                                return (
                                                                    item.id === constsApi.channel[1].id &&
                                                                    <Dropdown.Item key={index} eventKey="chamadaComNumero"
                                                                        onClick={() => props.setIsDefaultClick()} onMouseOver={() => {
                                                                            props.itemDropOverlay("responseNumber")
                                                                        }} onMouseOut={() => {
                                                                            props.itemDropOut("responseNumber")
                                                                        }}>
                                                                        <Row className="d-flex align-items-center py-2">
                                                                            <Col sm={2} md={2}>
                                                                                <svg id="Grupo_11310" data-name="Grupo 11310"
                                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                                    height="24" viewBox="0 0 18.692 18.692">
                                                                                    <path id="Caminho_9669" data-name="Caminho 9669"
                                                                                        d="M0,0H18.692V18.692H0Z" fill="none" />
                                                                                    <path id="Caminho_9670" data-name="Caminho 9670"
                                                                                        d="M4.558,4H7.673L9.231,7.894,7.284,9.063a8.567,8.567,0,0,0,3.894,3.894l1.168-1.947,3.894,1.558v3.115a1.558,1.558,0,0,1-1.558,1.558A12.462,12.462,0,0,1,3,5.558,1.558,1.558,0,0,1,4.558,4"
                                                                                        transform="translate(-0.663 -0.885)"
                                                                                        fill="none" stroke="#707070"
                                                                                        strokeLinecap="round" strokeLinejoin="round"
                                                                                        strokeWidth="1" />
                                                                                    <line id="Linha_197" data-name="Linha 197"
                                                                                        y1="3.894" x2="3.894"
                                                                                        transform="translate(11.683 3.115)"
                                                                                        fill="none" stroke="#707070"
                                                                                        strokeLinecap="round" strokeLinejoin="round"
                                                                                        strokeWidth="1" />
                                                                                    <path id="Caminho_9671" data-name="Caminho 9671"
                                                                                        d="M16,4h3.115V7.115"
                                                                                        transform="translate(-3.538 -0.885)"
                                                                                        fill="none" stroke="#707070"
                                                                                        strokeLinecap="round" strokeLinejoin="round"
                                                                                        strokeWidth="1" />
                                                                                </svg>
                                                                            </Col>

                                                                            <Col sm={8} md={8}>
                                                                                {props.t('home_tickets.messages_component.call_with')} <br /> {props.currentRequester_user.phone}
                                                                            </Col>

                                                                            <Col sm={1} md={1} id="responseNumberCheck"
                                                                                style={{ display: 'none' }}>
                                                                                {props.CheckIcon()}
                                                                            </Col>
                                                                        </Row>
                                                                    </Dropdown.Item>
                                                                )
                                                            })}
                                                    </>
                                                }
                                                {props.onlyRead ?
                                                    <></>
                                                    :
                                                    <>
                                                        {
                                                            props.currentRequester_user && props.currentRequester_user?.channels && props.currentRequester_user?.channels?.map((item, index) => {
                                                                if (item.id === constsApi.channel[0].id) {
                                                                    return (
                                                                        <Dropdown.Item key={index} eventKey={props.t('home_tickets.messages_component.email')} id="E-mail"
                                                                            onClick={() => props.setIsDefaultClick()} onMouseOver={() => {
                                                                                props.itemDropOverlay("responseEmail")
                                                                            }} onMouseOut={() => {
                                                                                props.itemDropOut("responseEmail")
                                                                            }}>
                                                                            <Row className="d-flex align-items-center  py-2">
                                                                                <Col sm={2} md={2}>
                                                                                    <svg id="Grupo_11322" data-name="Grupo 11322"
                                                                                        xmlns="http://www.w3.org/2000/svg" width="24"
                                                                                        height="24" viewBox="0 0 18.69 18.69">
                                                                                        <path id="Caminho_9699" data-name="Caminho 9699"
                                                                                            d="M0,0H18.69V18.69H0Z" fill="none" />
                                                                                        <rect id="Retângulo_18003"
                                                                                            data-name="Retângulo 18003" width="13.897"
                                                                                            height="10.897" rx="2"
                                                                                            transform="translate(2 3.793)" fill="none"
                                                                                            stroke="#707070" strokeLinecap="round"
                                                                                            strokeLinejoin="round" strokeWidth="1" />
                                                                                        <path id="Caminho_9700" data-name="Caminho 9700"
                                                                                            d="M3,7l7,4.7,7-4.7"
                                                                                            transform="translate(-1.048 -1.559)"
                                                                                            fill="none" stroke="#707070"
                                                                                            strokeLinecap="round" strokeLinejoin="round"
                                                                                            strokeWidth="1" />
                                                                                    </svg>
                                                                                </Col>
                                                                                <Col sm={8} md={8}>
                                                                                    {props.t('home_tickets.messages_component.email')}
                                                                                </Col>

                                                                                <Col sm={1} md={1} id="responseEmailCheck"
                                                                                    style={{ display: 'none' }}>
                                                                                    {props.CheckIcon()}
                                                                                </Col>
                                                                            </Row>
                                                                        </Dropdown.Item>
                                                                    )
                                                                }
                                                            }
                                                            )}
                                                    </>
                                                }
                                                {props.onlyRead ?
                                                    <></>
                                                    :
                                                    <>
                                                        {
                                                            props.currentRequester_user && props.currentRequester_user.channels &&

                                                            props.currentRequester_user.channels.map((item: { id: string; }, index: React.Key) => {
                                                                return (
                                                                    item.id === constsApi.channel[5].id &&
                                                                    <Dropdown.Item key={index} eventKey={props.t('home_tickets.messages_component.webchat')} id="Webchat"
                                                                        onClick={() => props.setIsDefaultClick()} onMouseOver={() => {
                                                                            props.itemDropOverlay("responseWebchat")
                                                                        }} onMouseOut={() => {
                                                                            props.itemDropOut("responseWebchat")
                                                                        }}>
                                                                        <Row className="d-flex align-items-center  py-2">
                                                                            <Col sm={2} md={2}>
                                                                                <svg id="Grupo_12897" data-name="Grupo 12897" xmlns="http://www.w3.org/2000/svg" width="17.974" height="17.974" viewBox="0 0 17.974 17.974">
                                                                                    <path id="Caminho_10311" data-name="Caminho 10311" d="M0,0H17.974V17.974H0Z" fill="none" />
                                                                                    <path id="Caminho_10312" data-name="Caminho 10312" d="M18.238,11.238,15.991,8.991H10.749A.749.749,0,0,1,10,8.242V3.749A.749.749,0,0,1,10.749,3h6.74a.749.749,0,0,1,.749.749v7.489" transform="translate(-2.511 -0.753)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                                                    <path id="Caminho_10313" data-name="Caminho 10313" d="M11.238,13.745v1.5a.749.749,0,0,1-.749.749H5.247L3,18.238V10.749A.749.749,0,0,1,3.749,10h1.5" transform="translate(-0.753 -2.511)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                                                </svg>
                                                                            </Col>
                                                                            <Col sm={8} md={8}>
                                                                                {props.t('home_tickets.messages_component.webchat')}
                                                                            </Col>

                                                                            <Col sm={1} md={1} id="responseWebchatCheck"
                                                                                style={{ display: 'none' }}>
                                                                                {props.CheckIcon()}
                                                                            </Col>
                                                                        </Row>
                                                                    </Dropdown.Item>
                                                                )
                                                            })}
                                                    </>
                                                }
                                                {props.onlyRead ?
                                                    <></>
                                                    :
                                                    <>
                                                        {
                                                            props.currentRequester_user && props.currentRequester_user.channels &&

                                                            props.currentRequester_user.channels.map((item: { id: string; }, index: React.Key) => {
                                                                return (
                                                                    item.id === constsApi.channel[3].id &&
                                                                    <Dropdown.Item key={index} eventKey={props.t('home_tickets.messages_component.facebook')} id="Facebook"
                                                                        onClick={() => props.setIsDefaultClick()} onMouseOver={() => {
                                                                            props.itemDropOverlay("responseFacebook")
                                                                        }} onMouseOut={() => {
                                                                            props.itemDropOut("responseFacebook")
                                                                        }}>
                                                                        <Row className="d-flex align-items-center  py-2">
                                                                            <Col sm={2} md={2}>
                                                                                <svg id="Grupo_11496" data-name="Grupo 11496" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 2 17.281 17.281">
                                                                                    <path id="Caminho_9748" data-name="Caminho 9748" d="M0,0H17.281V17.281H0Z" fill="none" />
                                                                                    <path id="Caminho_9749" data-name="Caminho 9749" d="M7,8.04v2.88H9.16v5.04h2.88V10.92H14.2l.72-2.88H12.04V6.6a.72.72,0,0,1,.72-.72h2.16V3H12.76a3.6,3.6,0,0,0-3.6,3.6V8.04H7" transform="translate(-1.96 -0.84)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                                                </svg>
                                                                            </Col>
                                                                            <Col sm={8} md={8}>
                                                                                {props.t('home_tickets.messages_component.facebook')}
                                                                            </Col>

                                                                            <Col sm={1} md={1} id="responseFacebookCheck"
                                                                                style={{ display: 'none' }}>
                                                                                {props.CheckIcon()}
                                                                            </Col>
                                                                        </Row>
                                                                    </Dropdown.Item>
                                                                )
                                                            })}
                                                    </>
                                                }
                                                {props.onlyRead ?
                                                    <></>
                                                    :
                                                    <>
                                                        {
                                                            props.currentRequester_user && props.currentRequester_user.channels &&

                                                            props.currentRequester_user.channels.map((item: { id: string; }, index: React.Key) => {
                                                                return (
                                                                    item.id === constsApi.channel[4].id &&
                                                                    <Dropdown.Item key={index} eventKey={props.t('home_tickets.messages_component.instagram')} id="Instagram"
                                                                        onClick={() => props.setIsDefaultClick()} onMouseOver={() => {
                                                                            props.itemDropOverlay("responseInstagram")
                                                                        }} onMouseOut={() => {
                                                                            props.itemDropOut("responseInstagram")
                                                                        }}>
                                                                        <Row className="d-flex align-items-center  py-2">
                                                                            <Col sm={2} md={2}>
                                                                                <svg id="Grupo_11497" data-name="Grupo 11497" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 1 18.91 18.91">
                                                                                    <path id="Caminho_9750" data-name="Caminho 9750" d="M0,0H18.91V18.91H0Z" fill="none" />
                                                                                    <rect id="Retângulo_18238" data-name="Retângulo 18238" width="13" height="13" rx="4" transform="translate(3 2.91)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                                                    <circle id="Elipse_1529" data-name="Elipse 1529" cx="2.5" cy="2.5" r="2.5" transform="translate(7 6.91)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                                                    <line id="Linha_421" data-name="Linha 421" y2="0.001" transform="translate(13 5.909)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                                                </svg>
                                                                            </Col>
                                                                            <Col sm={8} md={8}>
                                                                                {props.t('home_tickets.messages_component.instagram')}
                                                                            </Col>

                                                                            <Col sm={1} md={1} id="responseInstagramCheck"
                                                                                style={{ display: 'none' }}>
                                                                                {props.CheckIcon()}
                                                                            </Col>
                                                                        </Row>
                                                                    </Dropdown.Item>
                                                                )
                                                            })}
                                                    </>
                                                }
                                                {props.onlyRead ?
                                                    <></>
                                                    :
                                                    <>
                                                        {
                                                            props.currentRequester_user && props.currentRequester_user.channels &&

                                                            props.currentRequester_user.channels.map((item: { id: string; }, index: React.Key) => {
                                                                return (
                                                                    item.id === constsApi.channel[2].id &&
                                                                    <Dropdown.Item key={index} eventKey={props.t('home_tickets.messages_component.whatsapp')} id="Whatsapp"
                                                                        onClick={() => props.setIsDefaultClick()} onMouseOver={() => {
                                                                            props.itemDropOverlay("responseWhats")
                                                                        }} onMouseOut={() => {
                                                                            props.itemDropOut("responseWhats")
                                                                        }}>
                                                                        <Row className="d-flex align-items-center  py-2">
                                                                            <Col sm={2} md={2}>
                                                                                <svg id="Grupo_11499" data-name="Grupo 11499"
                                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                                    height="24" viewBox="0 0 17.117 17.117">
                                                                                    <path id="Caminho_9745" data-name="Caminho 9745"
                                                                                        d="M0,0H17.117V17.117H0Z" fill="none" />
                                                                                    <path id="Caminho_9746" data-name="Caminho 9746"
                                                                                        d="M3,15.843l1.177-2.71A6.419,6.419,0,1,1,6.6,15.2L3,15.843"
                                                                                        transform="translate(-0.86 -0.865)"
                                                                                        fill="none" stroke="#707070"
                                                                                        strokeLinecap="round" strokeLinejoin="round"
                                                                                        strokeWidth="1" />
                                                                                    <path id="Caminho_9747" data-name="Caminho 9747"
                                                                                        d="M9,9.57a.357.357,0,1,0,.713,0V8.857a.357.357,0,1,0-.713,0V9.57a3.566,3.566,0,0,0,3.566,3.566h.713a.357.357,0,0,0,0-.713h-.713a.357.357,0,0,0,0,.713"
                                                                                        transform="translate(-2.581 -2.438)"
                                                                                        fill="none" stroke="#707070"
                                                                                        strokeLinecap="round" strokeLinejoin="round"
                                                                                        strokeWidth="1" />
                                                                                </svg>
                                                                            </Col>
                                                                            <Col sm={8} md={8}>
                                                                                {props.t('home_tickets.messages_component.whatsapp')}
                                                                            </Col>

                                                                            <Col sm={1} md={1} id="responseWhatsCheck"
                                                                                style={{ display: 'none' }}>
                                                                                {props.CheckIcon()}
                                                                            </Col>
                                                                        </Row>
                                                                    </Dropdown.Item>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }

                                                <Dropdown.Item
                                                    disabled={props.disabledDropdown}
                                                    eventKey={props.t('home_tickets.messages_component.internal_note')}
                                                    onClick={() => props.setIsDefaultClick()}
                                                    onMouseOver={() => {
                                                        props.itemDropOverlay("responseInternal")
                                                    }} onMouseOut={() => {
                                                        props.itemDropOut("responseInternal")
                                                    }}>
                                                    <Row className="d-flex align-items-center py-2">
                                                        <Col sm={2} md={2}>
                                                            <svg id="Grupo_11312" data-name="Grupo 11312"
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 18.69 18.69">
                                                                <path id="Caminho_9691" data-name="Caminho 9691"
                                                                    d="M0,0H18.69V18.69H0Z" fill="none" />
                                                                <path id="Caminho_9692" data-name="Caminho 9692"
                                                                    d="M18.972,13.4V4.486a1.486,1.486,0,1,0-2.972,0V13.4l1.486,1.486Z"
                                                                    transform="translate(-3.426 -0.771)" fill="none"
                                                                    stroke="#707070" strokeLinecap="round"
                                                                    strokeLinejoin="round" strokeWidth="1" />
                                                                <path id="Caminho_9693" data-name="Caminho 9693" d="M16,7h2.972"
                                                                    transform="translate(-3.426 -1.549)" fill="none"
                                                                    stroke="#707070" strokeLinecap="round"
                                                                    strokeLinejoin="round" strokeWidth="1" />
                                                                <path id="Caminho_9694" data-name="Caminho 9694"
                                                                    d="M14.145,16.944H4.486a1.486,1.486,0,1,1,0-2.972H7.458a1.486,1.486,0,0,0,0-2.972H5.229"
                                                                    transform="translate(-0.771 -2.237)" fill="none"
                                                                    stroke="#707070" strokeLinecap="round"
                                                                    strokeLinejoin="round" strokeWidth="1" />
                                                            </svg>
                                                        </Col>

                                                        <Col sm={8} md={8}>
                                                            {props.t('home_tickets.messages_component.internal_note')}
                                                        </Col>

                                                        <Col sm={1} md={1} id="responseInternalCheck" style={{ display: 'none' }}>
                                                            {props.CheckIcon()}
                                                        </Col>
                                                    </Row>
                                                </Dropdown.Item>

                                                <Dropdown.Item eventKey={props.t('home_tickets.messages_component.public_reply')}
                                                    onClick={() => props.setIsDefaultClick()} onMouseOver={() => {
                                                        props.itemDropOverlay("publicResponse")
                                                    }} onMouseOut={() => {
                                                        props.itemDropOut("publicResponse")
                                                    }}>
                                                    <Row className="d-flex align-items-center  py-2">
                                                        <Col sm={2} md={2}>
                                                            <svg id="Grupo_11322" data-name="Grupo 11322"
                                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                                height="24" viewBox="0 0 18.69 18.69">
                                                                <path id="Caminho_9699" data-name="Caminho 9699"
                                                                    d="M0,0H18.69V18.69H0Z" fill="none" />
                                                                <rect id="Retângulo_18003"
                                                                    data-name="Retângulo 18003" width="13.897"
                                                                    height="10.897" rx="2"
                                                                    transform="translate(2 3.793)" fill="none"
                                                                    stroke="#707070" strokeLinecap="round"
                                                                    strokeLinejoin="round" strokeWidth="1" />
                                                                <path id="Caminho_9700" data-name="Caminho 9700"
                                                                    d="M3,7l7,4.7,7-4.7"
                                                                    transform="translate(-1.048 -1.559)"
                                                                    fill="none" stroke="#707070"
                                                                    strokeLinecap="round" strokeLinejoin="round"
                                                                    strokeWidth="1" />
                                                            </svg>
                                                        </Col>
                                                        <Col sm={8} md={8}>
                                                            {props.t('home_tickets.messages_component.public_reply')}
                                                        </Col>
                                                        <Col sm={1} md={1} id="publicResponseCheck"
                                                            style={{ display: 'none' }}>
                                                            {props.CheckIcon()}
                                                        </Col>
                                                    </Row>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </OverlayTrigger>
                                </Col>
                                <Col md={7} style={{ height: `4.2vh` }} className='px-0'>
                                    <div id="idRequesterUserEmail">
                                        <Row className="d-flex justify-content-start align-items-center nopadding">
                                            <Col className='nopadding'>
                                                <svg id="Grupo_11322" data-name="Grupo 11322"
                                                    xmlns="http://www.w3.org/2000/svg" width="3.6%"
                                                    viewBox="0 0 18.69 18.69">
                                                    <path id="Caminho_9699" data-name="Caminho 9699" d="M0,0H18.69V18.69H0Z"
                                                        fill="none" />
                                                    <rect id="Retângulo_18003" data-name="Retângulo 18003" width="13.897"
                                                        height="10.897" rx="2" transform="translate(2 3.793)" fill="none"
                                                        stroke="#707070" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="1" />
                                                    <path id="Caminho_9700" data-name="Caminho 9700" d="M3,7l7,4.7,7-4.7"
                                                        transform="translate(-1.048 -1.559)" fill="none" stroke="#707070"
                                                        strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                </svg>

                                                <span style={{
                                                    textAlign: 'left',
                                                    font: 'var(--px15_19_Bold-font-Roboto)',
                                                    letterSpacing: '0px',
                                                    color: '#1E1E1E'
                                                }}
                                                > {props.t('home_tickets.messages_component.to_email')} </span>

                                                <OverlayTrigger
                                                    key='top'
                                                    placement='left'
                                                    trigger={['hover', 'focus', 'click']}
                                                    overlay={
                                                        <Tooltip id={'tooltip*email*' + (props.currentRequester_user !== null && props.currentRequester_user?.email)}>
                                                            {props.currentRequester_user && props.currentRequester_user?.email ? props.currentRequester_user?.email : props.t('home_tickets.messages_component.email_not_assigned')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            width: '90%'
                                                        }}
                                                        className='oneLineClampEllipsis'
                                                    >
                                                        <span
                                                            style={{
                                                                textAlign: 'left',
                                                                font: 'var(--px15_19-font-Roboto)',
                                                                letterSpacing: '0px',
                                                                color: '#707070',
                                                            }}
                                                        >
                                                            {props.currentRequester_user && props.currentRequester_user?.email ? props.currentRequester_user?.email : props.t('home_tickets.messages_component.email_not_assigned')}
                                                        </span>
                                                        <span
                                                            style={{ font: 'var(--px15_19_Bold-font-Roboto)' }}
                                                        >
                                                            {props.currentRequester_user && props.currentRequester_user?.email && props.currentRequester_user?.user_id === undefined ?
                                                                ` ${props.t('home_tickets.messages_component.create_consumer_on_ticket')}` : ""
                                                            }
                                                        </span>
                                                    </span>
                                                </OverlayTrigger>
                                            </Col>
                                            <Col md="4" className="d-flex justify-content-end align-items-center pe-0">
                                                <Button className='d-flex' style={{ backgroundColor: '#E8E8E8', border: 'none' }}>
                                                    <span onClick={() => { props.setShowModalCC_CCO(true) }}
                                                        style={{
                                                            textAlign: 'left',
                                                            font: 'var(--px15_19-font-Roboto)',
                                                            letterSpacing: '0px',
                                                            color: '#707070',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        {props.t('home_tickets.messages_component.cc_cco')}
                                                    </span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div id="idRequesterUserWhatshapp" style={{ display: 'none' }}>
                                        <Row >
                                            <Col>
                                                <svg id="Grupo_11499" data-name="Grupo 11499"
                                                    xmlns="http://www.w3.org/2000/svg" width="3.6%"
                                                    viewBox="0 0 17.117 17.117">
                                                    <path id="Caminho_9745" data-name="Caminho 9745"
                                                        d="M0,0H17.117V17.117H0Z" fill="none" />
                                                    <path id="Caminho_9746" data-name="Caminho 9746"
                                                        d="M3,15.843l1.177-2.71A6.419,6.419,0,1,1,6.6,15.2L3,15.843"
                                                        transform="translate(-0.86 -0.865)" fill="none" stroke="#1e1e1e"
                                                        strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                    <path id="Caminho_9747" data-name="Caminho 9747"
                                                        d="M9,9.57a.357.357,0,1,0,.713,0V8.857a.357.357,0,1,0-.713,0V9.57a3.566,3.566,0,0,0,3.566,3.566h.713a.357.357,0,0,0,0-.713h-.713a.357.357,0,0,0,0,.713"
                                                        transform="translate(-2.581 -2.438)" fill="none" stroke="#1e1e1e"
                                                        strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                </svg>


                                                <span style={{
                                                    textAlign: 'left',
                                                    font: 'var(--px15_19_Bold-font-Roboto)',
                                                    letterSpacing: '0px',
                                                    color: '#1E1E1E'
                                                }}
                                                > {props.t('home_tickets.messages_component.to_whatsapp')} </span>

                                                <OverlayTrigger
                                                    key='top'
                                                    placement='left'
                                                    trigger={['hover', 'focus', 'click']}
                                                    overlay={
                                                        <Tooltip id={'tooltip*whatsapp*' + (props.currentRequester_user !== null && props.currentRequester_user?.whatsapp)}>
                                                            {props.currentRequester_user && props.currentRequester_user.whatsapp ? props.currentRequester_user?.whatsapp : props.t('home_tickets.messages_component.whatsapp_not_assigned')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            textAlign: 'left',
                                                            font: 'var(--px15_19-font-Roboto)',
                                                            letterSpacing: '0px',
                                                            color: '#707070',
                                                        }} className='oneLineClampEllipsis'>
                                                        {
                                                            props.currentRequester_user && props.currentRequester_user?.whatsapp ? props.currentRequester_user?.whatsapp : props.t('home_tickets.messages_component.whatsapp_not_assigned')
                                                        }
                                                    </span>
                                                </OverlayTrigger>


                                            </Col>
                                        </Row>
                                    </div>
                                    <div id="idRequesterUserFacebook" style={{ display: 'none' }}>
                                        <Row>
                                            <Col >
                                                <svg id="Grupo_11496" data-name="Grupo 11496" xmlns="http://www.w3.org/2000/svg" width="17.281" height="17.281" viewBox="0 2 17.281 17.281">
                                                    <path id="Caminho_9748" data-name="Caminho 9748" d="M0,0H17.281V17.281H0Z" fill="none" />
                                                    <path id="Caminho_9749" data-name="Caminho 9749" d="M7,8.04v2.88H9.16v5.04h2.88V10.92H14.2l.72-2.88H12.04V6.6a.72.72,0,0,1,.72-.72h2.16V3H12.76a3.6,3.6,0,0,0-3.6,3.6V8.04H7" transform="translate(-1.96 -0.84)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                </svg>


                                                <span style={{
                                                    textAlign: 'left',
                                                    font: 'var(--px15_19_Bold-font-Roboto)',
                                                    letterSpacing: '0px',
                                                    color: '#1E1E1E'
                                                }}
                                                > {props.t('home_tickets.messages_component.to_facebook')} </span>

                                                <OverlayTrigger
                                                    key='top'
                                                    placement='left'
                                                    trigger={['hover', 'focus', 'click']}
                                                    overlay={
                                                        <Tooltip id={'tooltip*facebook*' + (props.currentRequester_user !== null && props.currentRequester_user?.name)}>
                                                            {props.currentRequester_user != null ? props.currentRequester_user?.name : props.t('home_tickets.messages_component.facebook_not_assigned')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            textAlign: 'left',
                                                            font: 'var(--px15_19-font-Roboto)',
                                                            letterSpacing: '0px',
                                                            color: '#707070',
                                                        }} className='oneLineClampEllipsis'>
                                                        {
                                                            props.currentRequester_user ? props.currentRequester_user?.name : props.t('home_tickets.messages_component.facebook_not_assigned')
                                                        }
                                                    </span>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div id="idRequesterUserInstagram" style={{ display: 'none' }}>
                                        <Row >
                                            <Col>
                                                <svg id="Grupo_11497" data-name="Grupo 11497" xmlns="http://www.w3.org/2000/svg" width="18.91" height="18.91" viewBox="0 1 18.91 18.91">
                                                    <path id="Caminho_9750" data-name="Caminho 9750" d="M0,0H18.91V18.91H0Z" fill="none" />
                                                    <rect id="Retângulo_18238" data-name="Retângulo 18238" width="13" height="13" rx="4" transform="translate(3 2.91)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                    <circle id="Elipse_1529" data-name="Elipse 1529" cx="2.5" cy="2.5" r="2.5" transform="translate(7 6.91)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                    <line id="Linha_421" data-name="Linha 421" y2="0.001" transform="translate(13 5.909)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                </svg>


                                                <span style={{
                                                    textAlign: 'left',
                                                    font: 'var(--px15_19_Bold-font-Roboto)',
                                                    letterSpacing: '0px',
                                                    color: '#1E1E1E'
                                                }}
                                                > {props.t('home_tickets.messages_component.to_instagram')} </span>

                                                <OverlayTrigger
                                                    key='top'
                                                    placement='left'
                                                    trigger={['hover', 'focus', 'click']}
                                                    overlay={
                                                        <Tooltip id={'tooltip*instagram*' + (props.currentRequester_user !== null && props.currentRequester_user?.name)}>
                                                            {props.currentRequester_user != null ? props.currentRequester_user?.name : props.t('home_tickets.messages_component.instagram_not_assigned')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            textAlign: 'left',
                                                            font: 'var(--px15_19-font-Roboto)',
                                                            letterSpacing: '0px',
                                                            color: '#707070',
                                                        }} className='oneLineClampEllipsis'>

                                                        {
                                                            props.currentRequester_user ? props.currentRequester_user?.name : props.t('home_tickets.messages_component.instagram_not_assigned')
                                                        }
                                                    </span>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div id="idRequesterUserWebChat" style={{ display: 'none' }}>
                                        <Row >
                                            <Col >
                                                <svg id="Grupo_12897" data-name="Grupo 12897" xmlns="http://www.w3.org/2000/svg" width="17.974" height="17.974" viewBox="0 0 17.974 17.974">
                                                    <path id="Caminho_10311" data-name="Caminho 10311" d="M0,0H17.974V17.974H0Z" fill="none" />
                                                    <path id="Caminho_10312" data-name="Caminho 10312" d="M18.238,11.238,15.991,8.991H10.749A.749.749,0,0,1,10,8.242V3.749A.749.749,0,0,1,10.749,3h6.74a.749.749,0,0,1,.749.749v7.489" transform="translate(-2.511 -0.753)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                    <path id="Caminho_10313" data-name="Caminho 10313" d="M11.238,13.745v1.5a.749.749,0,0,1-.749.749H5.247L3,18.238V10.749A.749.749,0,0,1,3.749,10h1.5" transform="translate(-0.753 -2.511)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                </svg>
                                                <span style={{
                                                    textAlign: 'left',
                                                    font: 'var(--px15_19_Bold-font-Roboto)',
                                                    letterSpacing: '0px',
                                                    color: '#1E1E1E'
                                                }}
                                                > {props.t('home_tickets.messages_component.to_webchat')} </span>

                                                <OverlayTrigger
                                                    key='top'
                                                    placement='left'
                                                    trigger={['hover', 'focus', 'click']}
                                                    overlay={
                                                        <Tooltip id={'tooltip*webchat*' + (props.currentRequester_user !== null && props.currentRequester_user?.name)}>
                                                            {props.currentRequester_user ? props.currentRequester_user?.name : props.t('home_tickets.messages_component.webchat_not_assigned')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            textAlign: 'left',
                                                            font: 'var(--px15_19-font-Roboto)',
                                                            letterSpacing: '0px',
                                                            color: '#707070',
                                                        }} className='oneLineClampEllipsis'>

                                                        {
                                                            props.currentRequester_user ? props.currentRequester_user?.name : props.t('home_tickets.messages_component.webchat_not_assigned')
                                                        }
                                                    </span>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                {(props.templateMessageList != null || props.messagesQuick.length > 0) && props.currentRequester_user !== null ?
                                    <Col md={1} className="d-flex justify-content-end">
                                        <ModalGeneralTypesController 
                                            show={props.showModalGeneralType}
                                            onHide={props.handleOpenCloseModalGeneralType}
                                            generalTypesVariables={props.generalTypesVariables}
                                            setAuxiTemplateMessage={props.setAuxiTemplateMessage}
                                            auxiTemplateMessage={props.auxiTemplateMessage}
                                            setTemplateMessage={props.setTemplateMessage}
                                            setTemplateParamsGeneralType={props.setTemplateParamsGeneralType}
                                        />
                                        <OverlayTrigger rootClose={props.showPopover} onToggle={() => props.showTemplateMessage()}
                                            show={props.showPopover} trigger={['click']} placement="top-end"
                                            overlay={popOverTemplateMessage}>
                                            <Button type="submit" className="nopadding buttonTicketsSec3 btn btn-primary"
                                                onClick={() => props.showTemplateMessage()}>
                                                <svg id="Grupo_12406" data-name="Grupo 12406"
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24">
                                                    <path id="Caminho_10006" data-name="Caminho 10006" d="M0,0H24V24H0Z"
                                                        fill="none" />
                                                    <path id="Caminho_10007" data-name="Caminho 10007"
                                                        d="M12,20,9,17H7a3,3,0,0,1-3-3V8A3,3,0,0,1,7,5H17a3,3,0,0,1,3,3v6a3,3,0,0,1-3,3H15l-3,3"
                                                        fill="none" stroke="#707070" strokeLinecap="round"
                                                        strokeLinejoin="round" strokeWidth="1.5" />
                                                    <line id="Linha_527" data-name="Linha 527" x2="8"
                                                        transform="translate(8 9)" fill="none" stroke="#707070"
                                                        strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    <line id="Linha_528" data-name="Linha 528" x2="6"
                                                        transform="translate(8 13)" fill="none" stroke="#707070"
                                                        strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                </svg>
                                            </Button>
                                        </OverlayTrigger>
                                    </Col>
                                    :
                                    <></>
                                }
                            </Row>
                            <Row className="nopadding" style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: '1'
                            }} //style={{ height: "min(max(112px, 14vh), 151px)"}}
                            >
                                <ChatEditor
                                    audioBlob={props.audioBlob}
                                    audioChunks={props.audioChunks}
                                    audio={props.audio}
                                    setAudioChunks={props.setAudioChunks}
                                    setAudio={props.setAudio}
                                    setAudioBlob={props.setAudioBlob}
                                    currentTicket={props.currentTicket}
                                    cleanFiles={props.messageSent}
                                    afterMessageSent={() => props.afterMessageSent()}
                                    onDropFiles={(ids) => props.onDropFiles(ids)} cleanMessageEditor={props.cleanMessageEditor}
                                    callbackGetStringHtml={props.callbackGetStringHtml}
                                    sendMessage={() => props.sendMessage()}
                                    showSubjectError={props.showSubjectError}
                                    templateMessage={props.templateMessage}
                                    // isAllowedMessage={props.isAllowedValidation()}
                                    messageChannel={props.currentChannel}
                                    setFilesIds={props?.setFilesIds}
                                    quickMessage={props.quickMessage}
                                    setQuickMessage={props.setQuickMessage}
                                    setChangeChatHeight={props.setChangeChatHeight}
                                    channelSelected={props.channelSelected}
                                    // showOptionsButton={props?.blockChatComponent}
                                    currentRequester_user={props.currentRequester_user}
                                    templateParamsGeneralType={props.templateParamsGeneralType}
                                ></ChatEditor>
                            </Row>
                        </Row>}
                </Col>
            </Row>
            {props.currentTicket != null
                ?
                <ModalCC_CCO_UpdateController show={props.showModalCC_CCO} updateEmails={props.updateCCandCCO} handleClose={props.handleClose} currentTicket={props.currentTicket} setSelectedConsumers={props.setSelectedConsumers} onSelectRequester={props.onSelectRequester} currentSelectedConsumer={props.currentRequester_user} />
                :
                <ModalCC_CCO_CreateController show={props.showModalCC_CCO} handleClose={props.handleClose} setSelectedConsumers={props.setSelectedConsumers} onSelectRequester={props.onSelectRequester} setCurrentRequester_user={props.setCurrentRequester_user} />
            }
        </>
    );
}

export default MessagesComponent;