import React, { FC, useEffect, useState } from 'react';
import AttachmentsInMessage from '.';
import { AttachmentsInMessageControllerProps } from './indexModel';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { getToken } from '../../../../../store/token';
import { useDropzone } from 'react-dropzone';
import { setShowAlertFeedback } from '../../../../../../store/internal';
import { Channels } from '../../../../../../core/enums/channels';
import { getToken } from '../../../../../../store/control_panel';
import { useTranslation } from 'react-i18next';
import { verifyCode } from '../../../../../../services/codeCxpressInterface';

const AttachmentsInMessageController: FC<AttachmentsInMessageControllerProps> = (props) => {
    const AppRequesterConst = new AppRequesterController();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [loading, setIsLoading] = useState(false);

    const [gfids, setGfids] = useState([]);
    const [files, setFiles] = useState([]);
    const [fileLoading, setFileLoading] = useState([]);
    const [lastId, setLastId] = useState(null);

    const [loadPreviewFiles, setLoadPreviewFiles] = useState(true);

    const values = {
        token: {
            value: useSelector(getToken),
        }
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        noClick: props?.visualization,
        noDrag: props?.visualization,
        multiple: false,
        minSize: 1,
        maxSize: 15728640,
        validator: (file) => {
            let findedFile = files.find(item => item.name === file.name);

            if (findedFile) {
              return {
                code: "file-name-finded",
                message: `Arquivo com mesmo nome encontrado`
              };
            }

            return null
        },
        disabled: false,
        /* noClick: isClicked,
        noDrag: isClicked, */
        onDrop: (dropedFiles) => {
            if (files.length === 0) {
                const formData = new FormData();
            const file = dropedFiles[0];

            formData.append("file", file);
            formData.append("channel_id", Channels.WhatsApp);
        
            const headers = {
                "Content-Type": "multipart/form-data; charset=utf-8",
                "Authorization": "Bearer " + values.token.value
            };

            fileLoading[files.length] = true;
            setFileLoading(fileLoading);

            const path = props?.createInCompany ? '/chat/upload/attachment' : '/panel/upload/attachment';
            AppRequesterConst.Post(
                path, formData, { headers },
                (response: Object) => {
                    return response;
                },
                (data: { data: any }) => {
                    dispatch(setShowAlertFeedback({ message: t("home_message_trigger.modal_register_message_trigger.upload_sucess"), visibility: true, signalIcon: true }));

                    // adicionando o gfid junto com os demais atributos
                    setFiles((allFiles) => {
                        allFiles.map((file, index) => { 
                            if (file.name === data.data.name) {
                                setLastId(data.data.id);
                                allFiles[index] = Object.assign(file, { gfid: data.data.id });
                            } else if (data?.data?.files?.[0]?.name) {
                                setLastId(data.data.files[0].id)
                                allFiles[index] = Object.assign(file, { gfid: data?.data?.files[0]?.id });
                            }
                        });
                        return allFiles;
                    });
                },
                (error: { response: { status: number; data: { message: any[]; code_cxpress: number }; }; }) => { 
                    if (error.response?.data?.message && error.response.data.message[0]) {
                        dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                    } else {
                        dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }));
                    }
                }, navigate, dispatch, setIsLoading, 
                () => { 
                    fileLoading[files.length] = false;
                    setFileLoading(fileLoading);
                }
            );
            } else {
                dispatch(setShowAlertFeedback({ visibility: true, message: t("home_departments.template_message_tab.remove_attachment"), signalIcon: false }));
            }
        },
        onDropRejected: (reject) => {
          if (reject[0].errors[0].code === "file-too-large") {
            dispatch(setShowAlertFeedback({ visibility: true, message: t("home_departments.template_message_tab.large_size"), signalIcon: false }));
          } else if (reject[0].errors[0].code === "file-name-finded") {
            dispatch(setShowAlertFeedback({ visibility: true, message: t("home_departments.template_message_tab.attachment_same_name"), signalIcon: false }));
          }
        },
        accept: 
            props.selectedChannelId == Channels.WhatsApp ?
            {
                'audio/aac': ['.aac'],
                'audio/amr': ['.amr'],
                'audio/mpeg': ['.mp3'],
                'audio/mp4': ['.m4a'],
                'audio/ogg': ['.ogg'],
                'text/plain': ['.txt'],
                'application/vnd.ms-excel': ['.xls'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                'application/vnd.ms-powerpoint': ['.ppt'],
                'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                'application/pdf': ['.pdf'],
                'image/jpeg': ['.jpeg'],
                'image/png': ['.png'],
                'image/webp': ['.webp'],
                'video/3gp': ['.3gp'],
                'video/mp4': ['.mp4']
            }:
            props.selectedChannelId == Channels.Email ?
            {
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                'application/vnd.ms-excel': ['.xls'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                'application/vnd.ms-powerpoint': ['.ppt'],
                'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                'application/vnd.ms-xpsdocument': ['.xps'],
                'image/vnd.microsoft.icon': ['.ico'],
                'application/oxps': ['.oxps'],
                'application/pdf': ['.pdf', '.ai'],
                'image/vnd.adobe.photoshop': ['.psd'],
                'application/postscript': ['.eps', '.ai', '.ps'],
                'application/eps': ['.eps', '.ai', '.ps'],
                'application/x-eps': ['.eps', '.ps'],
                'image/eps': ['.eps', '.ps'],
                'image/x-eps': ['.eps', '.ps'],
                'image/svg+xml': ['.svg'],
                'font/ttf': ['.ttf'],
                'application/zip': ['.zip'],
                'application/vnd.rar': ['.rar'],
                'application/x-tar': ['.tar'],
                'application/gzip': ['.gzip', '.gz'],
                'audio/mpeg': ['.mpeg', '.mp3'],
                'audio/wav': ['.wav'],
                'application/ogg': ['.ogg'],
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': ['.png'],
                'image/gif': ['.gif'],
                'image/bmp': ['.bmp'],
                'image/tiff': ['.tif', '.tiff'],
                'video/webm': ['.webm'],
                'video/mp4': ['.mp4'],
                'video/3gpp': ['.3gpp', '.3gp'],
                'video/quicktime': ['.mov'],
                'video/x-msvideo': ['.avi'],
                'video/x-ms-wmv': ['.wmv'],
                'text/plain': ['.txt'],
                'text/csv': ['.csv'],
                'text/calendar': ['.ics'],
                'audio/midi': ['.mid', '.midi'],
                'audio/x-midi': ['.mid', '.midi'],
                'video/mpeg': ['.mpeg'],
                'application/vnd.oasis.opendocument.presentation': ['.odp'],
                'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
                'application/vnd.oasis.opendocument.text': ['.odt'],
                'font/otf': ['.otf'],
                'application/rtf': ['.rtf'],
                'application/x-7z-compressed': ['.7z']
            } : {}
    });

    // passa os gfids com a callback
    useEffect(() => {
        props.setAttachmentsFilesIds(gfids);
    }, [gfids]);

    // adiciona o último gfid de um upload na listagem
    useEffect(() => {
        if (lastId !== null) {
            setGfids([...gfids, lastId]);
            setLastId(null);
        } 
    }, [lastId]);

    useEffect(() => {
        setLoadPreviewFiles((load) => {
            // carregando arquivos salvos
            if (load && props.previewFiles) {
                let files = props.previewFiles.map((item) => {
                    return { name: item.filename, url: item.url, gfid: item._id, isLoading: false };
                });
                
                setFiles(files);
    
                setGfids(files.map((item) => { return item.gfid }));
                setLoadPreviewFiles(false);
            } else { // edição normal dos arquivos
                let allFiles = files.concat(acceptedFiles);
    
                allFiles = [...new Map(allFiles.map(file => [file.name, file])).values()];
            
                allFiles.map(file => {
                    const returnedTarget = Object.assign(file, { isLoading: true });
                    file = returnedTarget;
                });
            
                if (allFiles.length > 1) {
                    allFiles = [allFiles[0]];
                }

                setFiles(allFiles);
            
                acceptedFiles.forEach((file, i) => { acceptedFiles.pop() });
            } 
            return load;
        });   

    }, [acceptedFiles, props.previewFiles]);

    const deleteFile = (fileName: any[]) => {
        let newFiles = files.filter((item) => item.name !== fileName);
        acceptedFiles.forEach((file, i) => { acceptedFiles.pop() });
        setFiles(newFiles);

        // gfids após deletar um arquivo
        let newGfids = newFiles.map((file, index) => { 
            if (file?.gfid)
                return file.gfid;
            else if (file?._id)
                return file._id;
        });
        setGfids(newGfids);
    }

    const handleDownloadFile = (file: any) => {
        const link = document.createElement('a');
        link.href = file.url;
        link.target = '_blank';
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    
    return <AttachmentsInMessage isLoading={loading} files={files} setFiles={setFiles} fileLoading={fileLoading} handleDownloadFile={handleDownloadFile} deleteFile={deleteFile} getRootProps={getRootProps} getInputProps={getInputProps} selectedChannelId={Channels.WhatsApp} visualization={props?.visualization}/>
}   

export default AttachmentsInMessageController;